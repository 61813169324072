<template>
  <div>
    <!-- Hero Start -->
    <section class="bg-half d-table w-100" style="background: url('/images/company/aboutus.jpg');">
      <div class="bg-overlay"></div>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-12 text-center">
            <div class="page-next-level title-heading">
              <div class="page-next">
                <nav aria-label="breadcrumb" class="d-inline-block">
                  <ul class="breadcrumb bg-white rounded shadow mb-0">
                    <li class="breadcrumb-item"><a href="#" class="text_custom">Contact Us</a></li>
                  </ul>
                </nav>
              </div>
            </div>
          </div><!--end col-->
        </div><!--end row-->
      </div> <!--end container-->
    </section><!--end section-->
    <div class="position-relative">
      <div class="shape overflow-hidden text-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
      </div>
    </div>
    <!-- Start Contact -->
    <section style="margin-top: -5%" class="section pb-0">
      <div class="container bg-custom pb-4 mb-4">
        <div class="row">
          <div class="container text_custom">
            <div class="card border-0 text-center features feature-clean">
              <div class="icons text-primary text-center mx-auto">
                <i class="uil uil-envelope d-block rounded h3 mb-0"></i>
              </div>
              <div class="content mt-3">
                <h5 class="fw-bold">Mailing Address</h5>
                <a href="mailto:contact@example.com" class="text_custom">{{ contactinformation.email }}</a>
              </div>
            </div>
          </div><!--end col-->
        </div><!--end row-->
        <div class="row text_custom">
          <div class="col-md-6" v-if="contactinformation.official_mobile_numbers">
            <h5 class="text-center">Official Mobile Numbers</h5>
            <table class="table mb-0 table-center">
              <thead class="bg-light text-custom2">
              <tr>
                <th scope="col" class="border-bottom text-center">SL.</th>
                <th scope="col" class="border-bottom text-center">Mobile Number</th>
              </tr>
              </thead>
              <tbody class="text-custom2">
              <tr v-for="(mb,key) in dataSplit(contactinformation.official_mobile_numbers)" :key="key">
                <th scope="row" class="text-center">{{ key + 1 }}</th>
                <td class="text-center">{{ mb }}</td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="col-md-6" v-if="contactinformation.cadet_calling_numbers">
            <h5 class="text-center">Cadets’ Calling Numbers</h5>
            <table class="table mb-0 table-center text-custom2">
              <thead class="bg-light">
              <tr>
                <th scope="col" class="border-bottom text-center">SL.</th>
                <th scope="col" class="border-bottom text-center">Mobile Number</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(mb,key) in dataSplit(contactinformation.cadet_calling_numbers)" :key="key">
                <th scope="row" class="text-center">{{ key + 1 }}</th>
                <td class="text-center">{{ mb }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div><!--end container-->
      <div class="container mt-80  bg-custom mb-4">
        <div class="row align-items-center">
          <div class="col-lg-5 col-md-6 pt-2 pt-sm-0 order-2 order-md-1">
            <div class="card shadow rounded border-0">
              <div class="card-body py-5">
                <h4 class="card-title text_custom">Get In Touch !</h4>
                <div class="custom-form mt-3 text_custom">
                  <form method="post" ref="contactForm" @submit="contactSubmit()" action="javascript:void(0)">
                    <p id="error-msg" class="mb-0 success-msg" v-html="success" style="color:green;"></p>
                    <p id="error-msg" class="mb-0 error-msg" v-html="error" style="color:red;"></p>
                    <div id="simple-msg"></div>
                    <div class="row">
                      <div class="col-md-6">
                        <div class="mb-3">
                          <label class="form-label">Your Name <span class="text-danger">*</span></label>
                          <div class="form-icon position-relative">
                            <input name="name" id="name" type="text" class="form-control ps-5"  required>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-6">
                        <div class="mb-3">
                          <label class="form-label">Your Email <span class="text-danger">*</span></label>
                          <div class="form-icon position-relative">
                            <input name="email_or_phone" id="email" type="email" class="form-control ps-5"  required>
                          </div>
                        </div>
                      </div><!--end col-->

                      <div class="col-12">
                        <div class="mb-3">
                          <label class="form-label">Subject <span class="text-danger">*</span></label>
                          <div class="form-icon position-relative">
                            <input name="subject" id="subject" class="form-control ps-5" required>
                          </div>
                        </div>
                      </div><!--end col-->

                      <div class="col-12">
                        <div class="mb-3">
                          <label class="form-label">Comments <span class="text-danger">*</span></label>
                          <div class="form-icon position-relative">
                            <textarea name="details" id="comments" rows="4" class="form-control ps-5" required></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <div class="d-grid">
                          <button type="submit" name="submit" class="btn btn-primary">Send Message</button>
                        </div>
                      </div><!--end col-->
                    </div><!--end row-->
                  </form>
                </div><!--end custom-form-->
              </div>
            </div>
          </div><!--end col-->

          <div class="col-lg-7 col-md-6 order-1 order-md-2">
            <div class="card border-0">
              <div class="card-body p-0">
                <img :src="'/images/contact.svg'" class="img-fluid" alt="">
              </div>
            </div>
          </div><!--end col-->
        </div><!--end row-->
      </div><!--end container-->

      <div class="container-fluid">
        <div class="row">
          <div class="col-12 p-0">
            <div class="card map border-0">
              <div class="card-body p-0" v-html="contactinformation.map_location" style="border:0" allowfullscreen>
              </div>
            </div>
          </div><!--end col-->
        </div><!--end row-->
      </div><!--end container-->
    </section><!--end section-->
    <!-- End contact -->
  </div>
</template>

<script>
//import $ from "jquery";
import apiCall from '../../core/apiCall';
import feather from 'feather-icons'
export default {
  name: 'ContactUs',
  data() {
    return {
      error: '',
      success: ''
    }
  },
  created() {
    if(this.contactinformation.length<1) this.$store.dispatch('GET_ALL_CONTACTINFORMATION');
  },
  methods: {
    dataSplit(dt){
      try{
        return JSON.parse(dt);
      }catch (e){
        return [];
      }
    },
    async contactSubmit() {
      await apiCall.post('/contact/form/information/store', new FormData(this.$refs.contactForm)).then((response) => {
        this.success = response.data.message;
        this.$refs.contactForm.reset();
        this.error= '';
      }).catch((error) => {
        this.success= '';
        this.error = error.response.data.message;
      })
    }
  },
  computed: {
    contactinformation() {
      return this.$store.getters.contactinformation;
    },
  },

  mounted() {
    feather.replace();
  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.table {
  margin: auto;
  width: 80%;
  padding: 10px;
}
td {
  border: 3px solid #0a58ca;
}

th {
  border: 3px solid #0a58ca;
}

</style>
<style scoped>
/*@import './../../assets/css/style.css';*/
@import './../../assets/css/style-dark.css';
</style>