
<template> 
<TABLE WIDTH=100% BORDER=0 CELLPADDING=0 CELLSPACING=0>
	<TR>
		<TD COLSPAN=2>
			<img SRC="/images/header/back1_01.gif" WIDTH=100% HEIGHT=100% ALT=""></TD>
		<TD ROWSPAN=3>
			<img SRC="/images/header/back1_02.gif" WIDTH=100% HEIGHT=100% ALT=""></TD>
		<TD ROWSPAN=3>
			<img SRC="/images/header/back1_03.gif" WIDTH=100% HEIGHT=100% ALT=""></TD>
		<TD ROWSPAN=3>
			<img SRC="/images/header/back1_04.gif" WIDTH=100% HEIGHT=100% ALT=""></TD>
		<TD ROWSPAN=3>
			<img SRC="/images/header/back1_05.gif" WIDTH=100% HEIGHT=100% ALT="">
            <div class="top-right">
                <b-input-group size="sm" style="margin-top: 3%;">
                <!-- <b-form-input id="searchbar_background" style="width:100px; background-color: #fae33b; text-decoration: black; " placeholder="Search"></b-form-input> -->
                <b-input-group-append>
                  <!-- <span id="searchbar_background" style="background-color: #fae33b; vertical-align: middle;" > -->
                    <!-- <b-icon icon="search" style="cursor: pointer; width: 25px; "></b-icon></span> -->
                  <a id="login_background" :href="backendUrl()" style="background-color: rgb(239 192 96);" onmouseover="this.style.backgroundColor='#3e8e41';" onmouseout="this.style.backgroundColor='rgb(219 166 56)';" class="pill-button" target="_blank">Login</a>
                </b-input-group-append>
              </b-input-group>
            </div>
        </TD>
	</TR>
	<TR>
		<TD>
			<img SRC="/images/header/back1_06.gif" WIDTH=100% HEIGHT=101 ALT=""></TD>
		<TD>
			<router-link :to="{name:'home'}"><img SRC="/images/header/back1_07.gif" WIDTH=100% HEIGHT=100% ALT=""></router-link></TD>
	</TR>
	<TR>
		<TD COLSPAN=2>
			<img SRC="/images/header/back1_08.gif" WIDTH=100% HEIGHT=100% ALT=""></TD>
	</TR>
</TABLE>
</template>

<script>
  export default {

       methods: {
    handleScroll () {

    },
    backendUrl(){
      //window.location.href=process.env.VUE_APP_ENV_BACKENDURL;
      return process.env.VUE_APP_ENV_BACKENDURL;
    }
  },
  };
</script>

<style>
/* Top right text */
.top-right {
  position: absolute;
  top: 40px;
  right: 16px;
}

.pill-button {
  display: inline-block; /* Allows the element to size based on content */
  padding: 10px 40px; /* Controls the size of the button */
  font-size: 16px; /* Adjusts the text size */
  font-weight: bold; /* Makes the text bold */
  color: white; /* Text color */
  background-color: rgb(219 166 56); /* Button background color */
  border: none; /* Removes any default borders */
  border-radius: 50px; /* Ensures a rounded, pill-like shape */
  text-decoration: none; /* Removes underline for links */
  text-align: center; /* Centers the text */
  cursor: pointer; /* Changes cursor to pointer on hover */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Adds a subtle shadow */
  transition: background-color 0.3s ease, box-shadow 0.3s ease; /* Smooth hover effect */
}

.pill-button:hover {
  background-color: #45a049; /* Darkens the button color on hover */
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.2); /* Increases shadow on hover */
}

.pill-button:active {
  background-color: #3e8e41; /* Even darker shade when clicked */
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2); /* Subtle inset shadow */
}
</style>